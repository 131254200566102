import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import logoFooter from '../../assets/images/BRAVO_ECHO_Footer.png'

const rowStyle = {
  padding: '10px',
  backgroundColor: '#000',
  color: '#d6d6d6',
  textAlign: 'center',
}
const footerImg = {
  maxHeight: '100px',
}

const year = new Date()

export default class Footer extends Component {
  render() {
    return (
      <Container>
        <Row style={rowStyle}>
          <Col
            md={2}
            className='d-flex flex-column justify-content-center align-items-center'
          >
            <Image
              src={logoFooter}
              style={footerImg}
              alt='Bravo Echo color logo'
            />
          </Col>
          <Col
            md={6}
            className='d-flex flex-column justify-content-center align-items-center'
          >
            <h6>Bravo Echo Web Development</h6>
            <small>3395 Sixes Rd Ste 2203, Canton, GA 30114</small>
            <h6>Copyright 2016 - {year.getFullYear()} | All Rights Reserved</h6>
          </Col>
          <Col
            md={4}
            className='d-flex flex-column justify-content-center align-items-center'
          >
            <h5>404.860.2142</h5>
            <h5>brian@bravoechoweb.com</h5>
            <small>
              <a href='/privacy'>Privacy Policy</a>
            </small>
          </Col>
        </Row>
      </Container>
    )
  }
}
